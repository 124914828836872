/* Reset some default browser styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

body {
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.landing-page {
  display: flex;
  flex-direction: column;
  flex: 1; /* Ensure it takes the remaining space */
  text-align: center;
  color: white;
   height: 100%;
}

.background-image {
  background-image: url('./landscape.jpg'); /* Replace with actual URL of the Dubai landscape image */
  background-size: cover;
  background-position: center;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  position: relative;
}

.background-image::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: inherit;
  background-size: inherit;
  background-position: inherit;
  filter: blur(8px) brightness(50%); /* Adjust the blur and darken effect as needed */
  z-index: 1;
}

.content {
  position: relative;
  z-index: 2;
    min-height: 95vh;
    justify-content: center;
  background: rgba(0, 0, 0, 0);
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

footer {
  background: black;
    min-height: 5vh;
  padding: 10px;
}
